import * as React from 'react';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';
import Grid from '../Grid/Grid';
import Menu from './Menu/Menu';
import A from '../Links/A';
import BottomPart from './BottomPart/BottomPart';
import BottomPartMobile from './BottomPart/BottomPartMobile';
import MenuBlock from 'styleguide/components/Footer/Menu/MenuBlock';
import Span from 'styleguide/components/Span/Span';
import Image from 'styleguide/components/Image/Image';
import Link from 'styleguide/components/Links/Link';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import {
  accountPath,
  blogPath,
  careersPath,
  cartPath,
  contactUsPath,
  helpCenterPath,
  hoursAndLocationPath,
  ourStoryPath,
  products,
  servicePath,
  siteMapPath,
  socialPath,
} from 'bundles/App/routes';
import UserContext from 'contexts/UserContextContainer/UserContext';
import AppContext from 'contexts/AppContext/AppContext';
import logo from 'assets/images/printivity-logo.png';
import { preloadComponent } from 'app/bundles/App/DynamicPages.imports-loadable';
import { clickEmailUs, clickGraphicDesignLink } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';
import { useLocation } from 'react-router-dom';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

interface Props {
  withoutSubscriptionForm?: boolean;
}

const MobileFooter = loadable(() => import('styleguide/components/MobileFooter/MobileFooter'));
const SubscriptionForm = loadable(() => import('styleguide/components/SubscriptionForm/SubscriptionForm'));

const Footer = ({ withoutSubscriptionForm }: Props) => {
  const { currentUser } = React.useContext(UserContext);
  const appContext = React.useContext(AppContext);
  const location = useLocation();

  return (
    <>
      {!currentUser && !withoutSubscriptionForm && <SubscriptionForm />}
      <IfMobile>
        <LazyLoad once placeholder={LoaderWrapper('h-[405px] flex items-center justify-center')}>
          <MobileFooter />
        </LazyLoad>
      </IfMobile>
      <Grid.Container className="mt-2 sm:mt-32">
        <Grid>
          <Menu>
            <Menu.Block title="Products">
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={products}
                onMouseOver={() => preloadComponent('ProductListingPage')}
              >
                All Products
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/business-cards"
                onMouseOver={() => preloadComponent('ProductDetailsPage')}
              >
                Business Cards
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/marketing/postcards"
                onMouseOver={() => preloadComponent('ProductDetailsPage')}
              >
                Postcards
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/signage/banners"
                onMouseOver={() => preloadComponent('ProductDetailsPage')}
              >
                Banners
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/landing/booklets"
                onMouseOver={() => preloadComponent('ProductDetailsPage')}
              >
                Booklets
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/books"
                onMouseOver={() => preloadComponent('CategoryPage')}
              >
                Books
              </Link>
              <Link className="text-base leading-6" underline="none" color="black" to="/marketing/flyers">
                Flyers
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to="/copies/color-copies"
                onMouseOver={() => preloadComponent('ProductDetailsPage')}
              >
                Copies
              </Link>
            </Menu.Block>
            <Menu.Block title="Services">
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={servicePath.rushCriticalPath}
                onMouseOver={() => preloadComponent('RushCritical')}
              >
                Rush Critical
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={servicePath.franchiseSolutionsPath}
                onMouseOver={() => preloadComponent('FranchiseSolutions')}
              >
                Franchise Solutions
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={servicePath.boxDesignPath}
                onMouseOver={() => preloadComponent('BoxDesign')}
              >
                Box Design
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={servicePath.designServices}
                onMouseOver={() => preloadComponent('DesignServices')}
                onClick={() =>
                  isProductPage(location.pathname, appContext.store.cmsPages) &&
                  clickGraphicDesignLink({ location: 'pdp_footer' })
                }
              >
                Graphic Design Services
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={servicePath.cloudEnterpriseServices}
                onMouseOver={() => preloadComponent('CloudEnterpriseServices')}
              >
                Cloud Enterprise Services
              </Link>
              <MenuBlock className="mt-5" title="Company">
                <Span className="text-base leading-6">
                  <Link
                    to={ourStoryPath}
                    data-cy="footer-our-story"
                    color="black"
                    underline="none"
                    onMouseOver={() => preloadComponent('OurStoryPage')}
                  >
                    Our Story
                  </Link>
                </Span>
                <Link
                  to={careersPath}
                  data-cy="footer-careers"
                  className="text-base leading-6"
                  underline="none"
                  color="black"
                  onMouseOver={() => preloadComponent('CareersPage')}
                >
                  Careers
                </Link>
                <A className="text-base leading-6" underline="none" color="black" href={blogPath}>
                  Blog
                </A>
                <Link
                  to={siteMapPath}
                  className="text-base leading-6"
                  underline="none"
                  color="black"
                  onMouseOver={() => preloadComponent('SiteMap')}
                >
                  Sitemap
                </Link>
              </MenuBlock>
            </Menu.Block>
            <Menu.Block title="Customer Care">
              <Link
                className="text-base leading-6"
                to={helpCenterPath}
                color="black"
                underline="none"
                onMouseOver={() => preloadComponent('HelpCenterPage')}
              >
                Help Center
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={contactUsPath}
                data-cy="footer-contact-us"
                onMouseOver={() => preloadComponent('ContactUsPage')}
              >
                Contact
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={hoursAndLocationPath}
                onMouseOver={() => preloadComponent('HoursAndLocation')}
              >
                Hours and Location
              </Link>
              <MenuBlock className="mt-5" title="Connect With Us">
                <A className="text-base leading-6" underline="none" color="black" href={socialPath.instagram}>
                  Instagram
                </A>
                <A className="text-base leading-6" underline="none" color="black" href={socialPath.facebook}>
                  Facebook
                </A>
                <A className="text-base leading-6" underline="none" color="black" href={socialPath.twitter}>
                  Twitter
                </A>
              </MenuBlock>
            </Menu.Block>
            <Menu.Block title="Your Account" className="relative">
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={accountPath.orders}
                onMouseOver={() => preloadComponent('OrderHistoryPage')}
              >
                Orders
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={accountPath.account}
                onMouseOver={() => preloadComponent('OrderHistoryPage')}
              >
                Account
              </Link>
              <Link
                className="text-base leading-6"
                underline="none"
                color="black"
                to={cartPath}
                onMouseOver={() => preloadComponent('CartPage')}
              >
                Cart
              </Link>
              <Link to="/" color="none" underline="none" onMouseOver={() => preloadComponent('HomePage')}>
                <Image
                  url={logo}
                  title="Printivity"
                  alt="Printivity"
                  className="relative right-0 top-[90px] min-w-[265px] lg:right-[-34%] lg:top-[75px] lg:min-w-[300px]"
                />
              </Link>
            </Menu.Block>
            <Menu.Block title="Hours & Contact Info">
              <Span className="text-base leading-6">Monday-Friday | 9:30am-8pm ET</Span>
              <Span className="text-base leading-6">
                <A href="tel:1-877-649-5463" underline="none" color="black">
                  1-877-649-5463
                </A>
              </Span>
              <Span className="text-base leading-6">
                <Link
                  underline="active"
                  to={contactUsPath}
                  data-cy="footer-contact-us-2"
                  color="black"
                  onMouseOver={() => preloadComponent('ContactUsPage')}
                  onClick={() =>
                    isProductPage(location.pathname, appContext.store.cmsPages) &&
                    clickEmailUs({ location: 'pdp_footer' })
                  }
                >
                  Email Us
                </Link>
              </Span>
            </Menu.Block>
          </Menu>
          <IfMobile>
            <Grid.Row className="flex w-full md:mt-20">
              <Grid.Col>
                <BottomPartMobile />
              </Grid.Col>
            </Grid.Row>
          </IfMobile>
          <IfTabletOrDesktop>
            <Grid.Row className="flex w-full md:mt-20">
              <Grid.Col md={2} lg={3} />
              <Grid.Col md={8} lg={6}>
                <BottomPart />
              </Grid.Col>
              <Grid.Col md={2} lg={3} />
            </Grid.Row>
          </IfTabletOrDesktop>
        </Grid>
      </Grid.Container>
    </>
  );
};

export default Footer;
