import * as React from 'react';
import Modal from 'styleguide/components/Modal/Modal';
import { Button } from 'styleguide/components';
import { FileVersion, LineItem, Order, Upload } from 'api/orders/types';
import UploadAreaWithStatus from 'styleguide/components/UploadAreaWithStatus/UploadAreaWithStatus';
import Loader from 'styleguide/components/Loader/Loader';
import { ProofApproval } from 'bundles/App/pages/ProofApprovalsPage/types';
import Image from 'styleguide/components/Image/Image';
import thumbnailImage from 'assets/images/DesignServices/thumbnail.png';
import { type OrderDispatchType } from 'app/contexts/OrderContext/actions';

interface Props {
  order?: Order;
  lineItem?: LineItem;
  owner?: FileVersion | ProofApproval;
  ownerType?: string;
  onClose: () => void;
  onRemoveUpload?: (dispatch: OrderDispatchType, id: number) => void;
  onAddUpload?: (dispatch: OrderDispatchType, id: number, upload: Upload) => void;
  dispatch?: OrderDispatchType;
  filesFromProps?: Upload[];
}

const UploadFilesModal = ({
  order,
  lineItem,
  owner,
  ownerType,
  onClose,
  onRemoveUpload,
  onAddUpload,
  dispatch,
  filesFromProps,
}: Props) => {
  const [status, setStatus] = React.useState<'ready' | 'loading'>('ready');

  return (
    <Modal
      onClose={onClose}
      shown
      className="w-full !max-w-2xl"
      title={
        <h2 data-cy="uploadFormTitle" className="font-hvBold text-2xl">
          {lineItem?.fileVersions?.length > 1 ? 'Revision Files' : 'Upload Files'}
        </h2>
      }
    >
      <div className="flex flex-col items-center text-center">
        {lineItem?.fileVersions?.length > 1 ? (
          <p className="!mt-0 self-start font-hvMedium text-gray-500">
            You’ll need to re-upload approved files.
          </p>
        ) : (
          <p className="!mt-0 self-start font-hvMedium text-gray-500">File Set</p>
        )}
        <UploadAreaWithStatus
          onLoading={() => setStatus('loading')}
          onReady={() => setStatus('ready')}
          lineItem={lineItem || null}
          owner={owner || null}
          ownerType={ownerType}
          onAddUpload={onAddUpload}
          onRemoveUpload={onRemoveUpload}
          dispatch={dispatch || null}
          filesFromProps={filesFromProps}
          order={order}
        />
        <div className="mt-6 w-full text-center md:flex md:text-right -md:mt-4">
          <div className="mb-2 flex items-center">
            <Image
              url={thumbnailImage}
              width={40}
              height={40}
              className="mr-2 rounded-full"
              alt="Design Services"
            />
            <div className="w-[90%] text-left text-xs -md:w-full">
              If your design is incomplete, don&apos;t worry! Upload whatever graphics or text you have and
              we&apos;ll send you a quote to get the file created for you
            </div>
          </div>
          {status === 'loading' ? (
            <Loader dataCy="uploadFormLoadingSpinner" />
          ) : (
            <Button
              dataCy="doneUploadingBtn"
              className="-md:w-full !px-2"
              type="button"
              color="blue"
              size="sm"
              onClick={onClose}
            >
              Done Uploading
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
